import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DateTime, Duration } from 'luxon';
import { timer } from 'rxjs';
import { NEGOTIATION_DURATION } from 'src/app/core/constants/orders/negotiation-duration';
import { NegotiationStatus } from 'src/app/core/enums/orders/negotiation-status';
import { Negotiation } from 'src/app/core/interfaces/orders/negotiation';
import { NegotiationsService } from 'src/app/core/services/api/negotiations.service';

@Component({
  selector: 'app-order-negotiation-timer',
  templateUrl: './order-negotiation-timer.component.html',
  styleUrl: './order-negotiation-timer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderNegotiationTimerComponent {
  protected readonly NegotiationStatus = NegotiationStatus;

  public negotiation = input.required<Negotiation>();
  public orderUuid = input.required<string>();

  protected negotiationTimer = computed(() => {
    this.timer();

    const createdAt = DateTime.fromISO(this.negotationCreatedAt());
    const duration = -createdAt.diffNow();
    const remaining = NEGOTIATION_DURATION.minus(duration);

    if (
      remaining.toMillis() < 0 &&
      ![
        NegotiationStatus.SENDER_ACCEPTED,
        NegotiationStatus.COMPLETED,
      ].includes(this.negotiation().status)
    ) {
      this.negotiationsService.expireNegotiation(this.orderUuid());
      return '00:00';
    }

    return remaining.toFormat('mm:ss');
  });

  private negotationCreatedAt = computed(() => this.negotiation().createdAt);
  private timer = toSignal(
    timer(0, Duration.fromObject({ second: 1 }).toMillis()),
    { initialValue: 0 },
  );

  constructor(private negotiationsService: NegotiationsService) {}
}
