@if (order$ | async; as order) {
  <app-dialog
    type="right"
    [alt]="true"
    [width]="400"
    [close$]="close$"
    (close)="dialogRef.close()"
  >
    <div class="title" title>
      <app-status-badge type="dot" [status]="order.status"></app-status-badge>
      <h3>{{ order.id }}</h3>

      @if (
        order.negotiation != null &&
        ![
          NegotiationStatus.SENDER_ACCEPTED,
          NegotiationStatus.COMPLETED
        ].includes(order.negotiation.status)
      ) {
        <app-order-negotiation-timer
          [negotiation]="order.negotiation"
          [orderUuid]="order.uuid"
        ></app-order-negotiation-timer>
      }
    </div>

    <div class="options" title-options>
      @if (user()?.company?.id == null) {
        <app-icon
          class="option"
          icon="edit"
          [width]="16"
          (click)="editOrder(order.uuid)"
        ></app-icon>
      }

      @if (
        user()?.company?.id == null ||
        [OrderStatus.SEARCHING_VEHICLE, OrderStatus.UNASSIGNED].includes(
          order.status.code
        ) ||
        (order.status.code === OrderStatus.INVALID && order.vehicle == null) ||
        (order.companyId == user()?.company?.id &&
          user()?.company?.type === CompanyType.CARRIER)
      ) {
        <app-icon
          class="option"
          icon="delete"
          [width]="16"
          (click)="deleteOrder(order.uuid)"
        ></app-icon>
      }
    </div>

    @if (order.errors; as errors) {
      <div class="errors">
        @for (error of order.errors; track error.message) {
          <p>
            {{ "orderError." + error.message | translate }}
          </p>
        }
      </div>
    }

    @if (order.negotiation != null) {
      <app-order-negotiation
        [order]="order"
        [negotiation]="order.negotiation"
        [(showTerms)]="showTerms"
      ></app-order-negotiation>
    }

    @if (showTerms()) {
      <app-order-terms [order]="order"></app-order-terms>
    } @else {
      @if (order.route; as route) {
        <app-route [route]="route"></app-route>
      }

      <app-order-details [order]="order"></app-order-details>
    }

    @if (order.negotiation == null) {
      <app-map [disabled]="true"></app-map>
    }
  </app-dialog>
}
