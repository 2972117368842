import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Order } from 'src/app/core/interfaces/orders/order';

@Component({
  selector: 'app-order-terms',
  templateUrl: './order-terms.component.html',
  styleUrl: './order-terms.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderTermsComponent {
  public order = input.required<Order>();
}
